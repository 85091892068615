/**
 * Created by ZengFanhui on 2020/10/26 10:40
 */
import {menuDataTranslate} from '../utils/common'
import {mapMutations} from 'vuex'

export const userMixin = {
  methods: {
    handleUserInfo(data) {
      if (!data) {
        return
      }
      // 储存用户信息
      const userInfo = {
        name: data.name,
        id: data.id,
        role: data.roleInfo.id,
        roleName: data.roleInfo.name
      }
      sessionStorage.setItem('userInfo', JSON.stringify(userInfo))
      // 转换菜单结构
      const menuModules = data.roleInfo.modules
      let menuList = menuDataTranslate(menuModules)
      console.log(menuList)
      this.setMenuList(menuList)
      // 将菜单存到sessionStorage中
      sessionStorage.setItem('menuList', JSON.stringify(menuList))
      // 这里不用vur-router跳转，是因为动态添加路由时，是根据sessionStorage里menuList的数据来判断的，
      // 而添加路由是在登录之前完成的，这时sessionStorage内还没有数据
      // 所以使用刷新当前页面来跳转
      // this.$router.push({path: process.env.VUE_APP_BASE_PATH}).then(r => console.log(r))
      window.location.href = process.env.VUE_APP_BASE_PATH
    },
    ...mapMutations({
      setMenuList: 'SET_MENU_LIST'
    })
  }
}
