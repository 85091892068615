<template>
  <div class="login-container" @keyup.enter="handleLogin">
    <el-row class="login-main">
      <div class="login-wrapper flex">
        <el-form :model="dataForm" :rules="dataRule" ref="loginDataForm" class="login-form" autoComplete="on"
                 label-position="left">
          <div class="title-container">
            <div class="logo"></div>
            <div class="title">肉品追溯管理后台</div>
          </div>
          <el-form-item prop="account">
            <i class="icon icon-account"></i>
            <el-input v-model="dataForm.account" type="text" autocomplete="on" placeholder="账号"/>
          </el-form-item>
          <el-form-item prop="password">
            <i class="icon icon-password"></i>
            <el-input v-model="dataForm.password" type="password" autocomplete="on" placeholder="密码"/>
          </el-form-item>
          <el-button class="btn-login" @click.native.prevent="handleLogin">登录</el-button>
        </el-form>
      </div>
    </el-row>
  </div>
</template>

<script>
  import {apiLogin} from '../../api/user'
  import {userMixin} from '../../mixins/user'

  export default {
    mixins: [userMixin],
    name: 'Login',
    data() {
      return {
        dataForm: {
          account: '',
          password: ''
        },
        dataRule: {
          account: [{required: true, message: '账号不能为空', trigger: 'blur'}],
          password: [{required: true, message: '密码不能为空', trigger: 'blur'}]
        }
      }
    },
    mounted() {
    },
    methods: {
      handleLogin() {
        this.$refs['loginDataForm'].validate((valid) => {
          if (valid) {
            apiLogin(this.dataForm.account, this.dataForm.password).then(({data}) => {
              this.handleUserInfo(data)
            })
          }
        })
      }
    }
  }
</script>

<style lang="stylus">
  @import "../../assets/style/variables.styl"

  /* reset element-ui css */
  .login-container
    .el-input
      display: inline-block;
      height: 40px
      width: 82%

      input
        background: transparent
        border: 0px
        -webkit-appearance: none
        border-radius: 0
        padding: 10px
        color: $color-text-input
        height: 40px

        &:-webkit-autofill
          -webkit-box-shadow: 0 0 0px 1000px $color-bg inset !important
          -webkit-text-fill-color: #fff !important

    .el-form-item
      border: 1px solid #e5e5e5
      background: #fff
      border-radius: 5px
      color: #454545

      .el-form-item__error
        margin-left: 36px
</style>

<style rel="stylesheet/stylus" lang="stylus" scoped>
  @import "../../assets/style/variables.styl"

  .login-container
    position: relative
    height: 100%
    width: 100%
    background-color: $white

    .login-main
      position: relative
      width: 100%

      .flex
        height: 100%
        display: flex

      .bg-center
        height: 80%

      .login-wrapper
        margin-top 150px
        position: relative
        justify-content center

        .login-form
          width: 250px

          .title-container
            position: relative

            .logo
              margin: 0 auto
              height: 56px
              width: 56px
              /*background: url("../../assets/images/logo.png") no-repeat center*/
              background-size: 100%

            .title
              font-size: 24px
              margin: 10px 0 40px
              font-weight: 500

          .icon
            display: inline-block
            vertical-align: sub
            width: 18px
            height: 18px
            background-size: 18px 18px
            background-repeat: no-repeat
            padding-right: 8px
            border-right: 1px solid #e5e5e5

            &.icon-account
              background-image: url("../../assets/images/account.png")

            &.icon-password
              background-image: url("../../assets/images/password.png")

          .btn-login
            background-color: #0077e8
            border: 1px solid #0077e8
            font-size: 17px
            width: 100%
            margin: 30px 0
            color: #fff
            border-radius: 5px
            box-shadow: rgba(0, 0, 0, 0.2) 1px 5px 5px 1px

</style>
